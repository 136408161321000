.review-card {
    width: 100%;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    text-align: center;
    position: relative;
}

.review-header {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 15px;
}

.reviewer-image {
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

.review-rating {
    color: #FFD700;
    font-size: 1.2em;
}

.review-text {
    font-size: 1em;
    color: #555;
    line-height: 1.5;
}
