.container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    margin: 0 auto;
    max-width: 1200px;
    padding: 20px;
  }
  
  .left-side {
    flex: 1;
    margin-right: 20px;
  }
  
  .right-section {
    flex: 1;
    position: sticky;
    top: 20px;
    align-self: flex-start;
  }
  
  .section-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .option-group {
    margin-bottom: 10px;
  }
  
  .option-item {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
  }
  
  .option-item label {
    margin-left: 10px;
  }
  
  .car-details {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 100%;
    max-width: 400px;
  }